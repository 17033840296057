import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'

import reportWebVitals from './reportWebVitals';

import Layout from "./pages/Layout/Layout";
// import Main from "./pages/Main/Main";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Catalog from "./pages/Catalog/Catalog";
import Product from "./pages/Product/Product";
import Advantages from "./pages/Advantages/Advantages";
import Contacts from "./pages/Contacts/Contacts";
// import SearchString from "./components/SearchString/SearchString";
import Page404 from "./components/404/404";

import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<About />} />
            <Route path="services/" element={<Services />} />
            <Route path="advantages/" element={<Advantages />} />
            {/*<Route path="catalog/" element={<Catalog />} />*/}
            {/*<Route path="catalog/:product_id" element={<Product />} />*/}
            <Route path="contacts/" element={<Contacts />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
